import React from "react"
import "./sidebar.css"

import willjw3 from "../../images/ML_Profile-2.jpg"

const Bio = ({ author, tagline }) => {
  return (
    <div className="bio-main w-75">
      <img
        src={willjw3}
        style={{ maxWidth: `100px` }}
        className="profile-img"
        alt=""
      />
      <h3 className="mt-2 author-bio">{author}</h3>
      <small className="text-muted">{tagline}</small>
    </div>
  )
}

export default Bio
